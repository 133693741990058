import Container from '@/layout/Container';
import JoinDailyGiveawayModal from '@/components/JoinDailyGiveawayModal';
import { FC, useEffect, useState } from 'react';
import moment from 'moment';

export const GiveawayCountdown = (props: { size?: 'sm' | 'default'; endDate: string }) => {
  const [time, setTime] = useState({
    days: 0,
    hours: 0,
    minutes: 0,
    seconds: 0,
  });

  useEffect(() => {
    const interval = setInterval(() => {
      const now = moment();
      const endDate = moment(props.endDate).endOf('day');
      const distance = endDate.diff(now);

      if (distance < 0) {
        clearInterval(interval);
        return;
      }

      const duration = moment.duration(distance);
      const daysRaw = duration.asDays();
      const days = Math.floor(daysRaw)
      const hours = duration.hours();
      const minutes = duration.minutes();
      const seconds = duration.seconds();

      setTime({ days, hours, minutes, seconds });
    }, 1000);

    return () => clearInterval(interval);  // Cleanup on component unmount
  }, [props.endDate]);

  return (
    <div className={props.size === 'sm' ? "relative flex items-start justify-between gap-4 px-2" : "relative flex h-[46px] items-start justify-between gap-4 px-10"}>
      <TimeSection value={time.days} label="DD" size={props.size} />
      <TimeSection value={time.hours} label="HH" size={props.size} />
      <TimeSection value={time.minutes} label="MM" size={props.size} />
      <TimeSection value={time.seconds} label="SS" size={props.size} />
    </div>
  );
};

const TimeSection: FC<{ value: number, label: string, size?: 'sm' | 'default' }> = ({ value, label, size }) => {
  const textSize = size === 'sm' ? 'text-xs' : 'text-lg';
  const labelSize = size === 'sm' ? 'text-[9px]' : 'text-[13px]';

  return (
    <div className="flex flex-col items-center justify-center gap-2 text-white">
      <div className={`font-chakra ${textSize} font-bold leading-[1]`}>
        {String(value).padStart(2, '0')}
      </div>
      <div className={`font-chakra ${labelSize} font-bold leading-[1] text-emerald-500`}>
        {label}
      </div>
    </div>
  );
};

export default function WeeklyGiveaway() {
  const [giveaway, setGiveaway] = useState<any>();
  const [isLoading, setIsLoading] = useState<boolean>(false);

  const fetchGiveaway = async () => {
    try {
      setIsLoading(true);
      const response = await fetch('/api/giveaway/today');
      const data = await response.json();
      setGiveaway(data.docs);
      setIsLoading(false);
    } catch (error) {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    fetchGiveaway();
  }, []);

  if (isLoading || !giveaway) {
    return <></>
  }

  return (
    <div
      className="relative bg-cover bg-no-repeat py-12"
      style={{ backgroundImage: 'url("/images/giveaway-bg.jpg")' }}
    >
      <Container className="relative z-10 text-white">
        <div className="flex flex-col items-center justify-center pt-12 lg:flex-row">
          <div className="mx-auto flex items-center justify-center gap-7 px-12">
            <img
              loading="lazy"
              src="/images/giveaway-box.png"
              className="hidden h-full w-full select-none lg:block"
              alt="Giveaway Box"
            />
          </div>
          <div className="flex w-fit flex-col gap-7">
            <h3 className="font-chakra text-5xl font-bold uppercase italic">
              GIVEAWAY
            </h3>
            <div>
              <h4 className="font-chakra max-w-2xl shrink-0 text-5xl font-bold uppercase italic">
                <span className="text-green-500">SELL</span> AT LEAST{' '}
                <span className="text-green-500">$5</span> TO JOIN
              </h4>
            </div>

            <div className="mr-auto text-3xl">
              <JoinDailyGiveawayModal />
            </div>

            <div className="relative flex h-[266px] w-full flex-col items-center pb-8 lg:w-[540px]">
              <div className="h-[266px] w-full overflow-hidden rounded-[20px] bg-white lg:w-[540px]">
                <img
                  loading="lazy"
                  className="h-full w-full object-cover"
                  src={giveaway.thumbnail}
                  alt="Giveaway Thumbnail"
                />
              </div>

              <div className="absolute -bottom-8 h-[66px] rounded-[20px] bg-gray-950 px-10 py-2.5">
                <GiveawayCountdown endDate={giveaway.endDate} />
              </div>
            </div>
          </div>
        </div>
      </Container>
    </div>
  );
}
