import { useEffect, useState } from 'react';

import Button from '@/components/ui/buttons/Button';
import { cn } from '@/lib/utils';
import Link from 'next/link';
import { GiveawayCountdown } from '@/components/WeeklyGiveaway';
import { FaTimes } from 'react-icons/fa';

export default function JoinDailyGiveawayModal() {
  const [open, show] = useState(false);
  const [loading, setIsLoading] = useState(false);
  const [giveaway, setGiveaway] = useState<any>();
  const [points, setPoints] = useState(0);
  const [stats, setStats] = useState<{
    totalValue: number;
    totalSold: number;
    actionIds: any[],
  }>({
    totalValue: 0,
    totalSold: 0,
    actionIds: [],
  });
  const fetchGiveaway = async () => {
    try {
      setIsLoading(true);
      const response = await fetch('/api/giveaway/today', { cache: 'no-store' });
      const data = await response.json();
      setGiveaway(data.docs);
      setIsLoading(false);
    } catch (error) {
      setIsLoading(false);
    }
  };

  const fetchMyStats = async (giveawayId: string) => {
    try {
      const response = await fetch(`/api/giveaway/${giveawayId}/user-values`, { cache: 'no-store' });
      const data = await response.json();
      setStats({
        totalValue: data.totalValue,
        totalSold: data.totalSold,
        actionIds: data.actionIds,
      });
    } catch (error) {
      console.log('error');
    }
  };

  useEffect(() => {
    fetchGiveaway();
  }, []);

  useEffect(() => {
    if (giveaway) { // Check if giveaway exists
      fetchEntries(giveaway.id); // Pass the giveaway.id to your fetchEntires function
      fetchMyStats(giveaway.id);
    }
  }, [giveaway]);

  const fetchEntries = async (id: string) => {
    try {
      const response = await fetch(`/api/giveaway/${id}/entries`, { cache: 'no-store' });
      const result = await response.json();

      setPoints(result.points);
    } catch (error) {
      console.error(error);
    }
  };

  const claimAction = async (actionId: string) => {
    try {
      const response = await fetch(`/api/giveaway/${giveaway.id}/claim`, {
        cache: 'no-store',
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({ actionId }),
      });

      setStats(prevStats => ({
        ...prevStats,
        actionIds: [...prevStats.actionIds, actionId]
      }));

      await response.json();
      fetchEntries(giveaway.id);
    } catch (error) {
      console.error(error);
    }
  };

  return (
    <>
      <Button className="rounded-xl" onClick={() => show(true)}>
        Join giveaway
      </Button>
      {open && (
        <div className="fixed top-0 left-0 w-full h-full bg-black bg-opacity-60 z-50">
          <div className="flex items-center justify-center h-screen">
            <div className="bg-surface-200 rounded-lg w-full p-4 max-w-md">
              <div className="flex items-center gap-4 justify-between mb-4">
                <div className="text-2xl text-white font-bold">Giveaway</div>

                <button
                  type="button"
                  className="pr-2"
                  onClick={() => show(false)}
                >
                  <FaTimes className="text-white" />
                </button>
              </div>

              <div className="flex flex-col sm:flex-row gap-10 items-center">
                <div className="relative flex h-[125px] w-[176px] flex-col items-center">
                  <div className="h-[125px] w-[128px] overflow-hidden rounded-[20px] bg-white">
                    {giveaway && giveaway.thumbnail && <img
                      className="h-full w-full object-cover"
                      src={giveaway.thumbnail}
                    />}
                  </div>

                  <div className="absolute -bottom-8 rounded-[20px] bg-gray-950 px-2 py-2.5">
                    <GiveawayCountdown endDate={giveaway.endDate} size="sm" />
                  </div>
                </div>

                <div className="flex flex-col gap-2 font-secondary">
                  <div className="text-primary-50 text-xl font-semibold leading-[22.83px]">{giveaway.title}</div>
                  <div className="text-secondary-200 text-sm font-semibold">
                    <div>
                      Your entries: <span className="text-yellow-300">{points}</span>
                    </div>

                    <span className="text-[9px] text-secondary-200">Your stats based on giveaway period:</span>
                    <div>
                      Earned: <span className="text-yellow-300">${stats.totalValue.toFixed(2)}</span>
                    </div>
                    <div>
                      Sold items: <span className="text-yellow-300">{stats.totalSold}</span>
                    </div>
                  </div>

                  <div className="flex flex-col gap-0 text-xs">
                    {giveaway.awards.map((award: any, index: number) => award && (<span key={index}>
                      {index + 1}. {award}
                    </span>))}
                  </div>
                </div>
              </div>

              <div className="flex flex-col gap-2 mt-14">
                {giveaway.actions.map((action: any, index: number) => {
                  const isSellAmountRequirementNotMet = action.requirementType === 'SELL_AMOUNT' && stats.totalValue < action.requirementValue;
                  const isSellItemsRequirementNotMet = action.requirementType === 'SELL_ITEMS' && stats.totalSold < action.requirementValue;

                  return (
                    <div
                      key={index}
                      className="font-secondary text-base border border-white border-opacity-20 rounded-xl px-4 py-3 flex justify-between items-center">
                      <div className="flex flex-col text-secondary-200">
                        {action.requirementType === 'SELL_AMOUNT' && <>
                            <div>Sell ${action.requirementValue} Worth of Skins</div>
                        </>}
                        {action.requirementType === 'SELL_ITEMS' && <>Sell {action.requirementValue} items</>}
                        <span className="text-zinc-500 text-xs">for {action.entries} entries</span>
                      </div>

                      <div>
                        {stats.actionIds.includes(action.id) ? (
                          <span className="bg-primary-50 rounded-lg px-2 py-2 text-black text-xs">Claimed</span>
                        ) : (
                          <Button
                            disabled={isSellAmountRequirementNotMet || isSellItemsRequirementNotMet}
                            onClick={() => claimAction(action.id)}
                            variant="primary" className={cn(
                            'rounded-xl px-4 py-2 text-xs',
                            (isSellAmountRequirementNotMet || isSellItemsRequirementNotMet) && 'hover:text-black bg-gray-50 cursor-not-allowed opacity-20'
                          )}>Claim</Button>
                        )}
                      </div>
                    </div>
                  )
                })}
              </div>


              <Link href="/sell" className="mt-4 font-secondary text-3xl rounded-xl bg-primary-50 w-full text-black block text-center py-3">
                Sell skins
              </Link>

            </div>
          </div>

        </div>
      )}
    </>
  )
}