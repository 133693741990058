import * as React from 'react';
import {
  FaDiscord,
  FaFacebook,
  FaXTwitter
} from 'react-icons/fa6';
import TrustpilotReviewsLink from '@/components/TrustpilotReviewsLink';

import Button from '@/components/ui/buttons/Button';
import Link from 'next/link';

export default function JoinDiscord() {
  return (
    <div className="flex flex-col items-center pb-8">
      <div className="mb-8">
        <div className="text-center text-[44px] font-normal capitalize leading-[56px] text-white">
          JOIN OUR DISCORD FOR MORE
        </div>
        <div className="font-secondary text-center text-lg leading-7 text-stone-300">
          For Latest News And Interesting Offers
        </div>
      </div>
      <div className="mb-2 flex">
        <a
          href="https://discord.gg/rC8Swqg35y"
          target="_blank"
          className="block"
        >
          <Button variant="outline" leftIcon={FaDiscord}>
            Discord
          </Button>
        </a>

        <Link href="/sell">
          <Button variant="primary">Sell your skins</Button>
        </Link>
      </div>
      <div className="mb-4">
        <TrustpilotReviewsLink />
      </div>
      <div className="flex gap-4">
        <a
          href="https://facebook.com/sellpixels"
          target="_blank"
          className="text-secondary-200 hover:text-secondary-100 bg-surface-200 p-3 text-2xl"
        >
          <FaFacebook />
        </a>

        <a
          href="https://x.com/SellPixels"
          target="_blank"
          className="text-secondary-200 hover:text-secondary-100 bg-surface-200 p-3 text-2xl"
        >
          <FaXTwitter />
        </a>
      </div>
    </div>
  );
}
